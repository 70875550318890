@import "../../styles/mixins";
@import "../../styles/variables";

$toast-bg-color: #15182b;
$toast-dark-bg-color: lighten($toast-bg-color, 10%);
$txt-color: #8695a5;

$info-color: #1ba2f0;
$success-color: #45b658;
$error-color: #e65451;
$warning-color: #ebb636;

// Define a mixin for toast types
@mixin toast-type($color) {
    header {
        figure {
            svg {
                fill: $color;
                circle,
                path {
                    stroke: $color;
                }
            }
            figcaption {
                color: $color;
            }
        }
    }
}

.toaster {
    background-color: $toast-bg-color;
    width: 324px;
    padding: 16px;
    border-radius: 8px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 4px;

        figure {
            display: flex;
            align-items: center;
            gap: 8px;

            figcaption {
                font-size: $font-size-base;
            }
        }

        button {
            background-color: transparent !important;
            width: 24px;
            height: 24px;
            svg {
                width: 10px;
                path {
                    stroke: $txt-color;
                }
            }
            &:hover {
                background-color: $toast-dark-bg-color !important;
            }
        }
    }

    p {
        margin-left: calc(24px + 8px);
        color: $txt-color;
        font-size: $font-size-small;
    }

    &.info {
        @include toast-type($info-color);
    }

    &.success {
        @include toast-type($success-color);
    }

    &.error {
        @include toast-type($error-color);
    }

    &.warning {
        @include toast-type($warning-color);
    }
}
