@import "../../styles/mixins";

.zoom-controls {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 10;
    @include flex-row();
    column-gap: 6px;

    button {
        background-color: #275499 !important;
    }
}