.ripple-container {
    position: relative;
    overflow: hidden;
    display: inline-block; // Ensures ripple effect is contained within inline elements
    cursor: pointer;

    .ripple {
        position: absolute;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3); // Customize ripple color
        transform: scale(0);
        animation: ripple 0.6s linear;
        pointer-events: none;
    }

    @keyframes ripple {
        to {
            transform: scale(4);
            opacity: 0;
        }
    }
}
