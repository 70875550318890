@import "../../styles/variables";
@import "../../styles/mixins";

.protected-header {
    background: var(--header-bg-color);
    color: var(--header-txt-color);
    .logo-and-app-name {
        display: flex;
        column-gap: $spacing-small;

        img {
            height: 32px;
        }

        figcaption {
            font-size: $font-size-base;
        }
    }
    .navbar-and-silhouette {
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        @include flex-row();
        column-gap: 64px;

        nav {
            display: flex;

            a {
                height: $headerHeight;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: var(--header-txt-color);
                column-gap: 8px;
                padding: 0px $spacing-small;
                text-decoration: none;
                // transition: all 0.3s ease-in-out;
                text-transform: capitalize;
                cursor: pointer;
                font-size: $font-size-base;
                background: transparent;

                svg path {
                    fill: var(--header-txt-color);
                    stroke: var(--header-txt-color);
                    transition: all 0.3s ease-in-out;
                }

                label {
                    letter-spacing: 0.5px;
                    cursor: pointer;
                }

                &:hover,
                &.selected {
                    background: var(--header-selected-nav-link-bg-color);
                    color: var(--header-selected-nav-link-txt-color);

                    svg path {
                        fill: var(--header-selected-nav-link-txt-color);
                        stroke: var(--header-selected-nav-link-txt-color);
                    }
                }
            }
        }

        .user-info {
            @include flex-row();
            column-gap: 8px;
            cursor: pointer;
            .silhouette-and-info {
                @include flex-row();
                column-gap: 8px;
                .silhouette {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: rgba(46, 55, 101, 1);
                    color: #fff;
                    font-size: $font-size-base;
                    font-weight: 500;
                    letter-spacing: 0.4px;

                    @include flex-row();
                }
                .info {
                    @include flex-column(flex-start);
                    label {
                        font-size: $font-size-small;
                        font-weight: 500;
                        cursor: pointer;
                        line-height: 21px;
                        letter-spacing: 0.1px;
                    }
                    span {
                        font-size: $font-size-xsmall;
                        font-weight: 600;
                        line-height: 18px;
                        letter-spacing: 0.1px;
                    }
                }
            }
            .action-arrow {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.logout-menu {
    position: relative;
    .MuiPaper-root {
        margin-top: 7px;
        margin-left: 4px;
        overflow: initial;
        .MuiList-root {
            padding: 0;
            .MuiMenuItem-root {
                font-size: 1.6rem;
                padding: 8px 32px;
                color: #333;
                position: relative;
                border-radius: 4px;
                // &:hover {
                //     background-color: #f0f0f0;
                //     color: #000;
                //     border-radius: 4px;
                // }
    
                &:focus {
                    background-color: #e0e0e0;
                }
                // &::after {
                //     content: '';
                //     position: absolute;
                //     width: 10px;
                //     height: 10px;
                //     background-color: white;
                //     top: -5px;
                //     left: -5px;
                // }
            }
            &:hover {
                .MuiMenuItem-root {
                    // background-color: #f0f0f0;
                    color: #000;
                }
            }
        }
        &::before {
            content: '';
            position: absolute;
            top: -11px; /* Position the arrow above the menu */
            right: 11px; /* Position the arrow horizontally */
            border-width: 6px;
            border-style: solid;
            border-color: transparent transparent white transparent; /* White arrow pointing down */
        }
    }
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 48px; /* Position the arrow above the menu */
    //     right: 32px; /* Position the arrow horizontally */
    //     border-width: 6px;
    //     border-style: solid;
    //     border-color: transparent transparent white transparent; /* White arrow pointing down */
    //   }
}
