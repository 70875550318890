@import "../../styles/variables";
@import "../../styles/mixins";

$blockPadding: 8px;

.alert-box {
    @include alertBoxTheme(light);
    border: 1px solid var(--alertbox-border-color);
    border-radius: 4px;
    background: var(--alertbox-bg-color);
    position: relative;

    &__close {
        position: absolute;
        top: -16px;
        right: -16px;
        // background-color: #ffffff !important;
        width: 24px;
        height: 24px;
        svg {
            width: 10px;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: $blockPadding;
        .main-and-subheader {
            width: 100%;
            .main-heading {
                margin: 0;
                @include flex-row(flex-start);
                column-gap: 6px;
                align-items: flex-start;

                mark {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: var(--unresolved-bg);
                    margin-top: 9px;

                    &.resolved {
                        background: var(--resolved-bg);
                    }

                    &.unresolved {
                        background: var(--unresolved-bg);
                    }
                }

                label {
                    font-size: $font-size-base;
                    font-weight: 400;
                    color: var(--alertbox-header-color);
                }
            }
            .sub-heading {
                @include flex-row(space-between);
                margin: 0;

                figure {
                    @include flex-row(flex-start);
                    column-gap: 4px;

                    svg {
                        path {
                            fill: var(--alertbox-subheader-icon-color);
                        }
                    }

                    figcaption {
                        font-size: $font-size-small;
                        font-weight: 400;
                        color: var(--alertbox-subheader-color);
                    }
                }
            }
        }

        .thumbnails {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 8px;
            figure {
                width: 70px;
                height: 48px;
                position: relative;
                cursor: pointer;

                img, video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }


                &.play-icon {
                    display: flex;
                        flex-direction: row;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                    video {
                        // z-index: -1;
                        position: relative;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.4);
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 10px solid white; /* Play icon color */
                        border-top: 6px solid transparent;
                        border-bottom: 6px solid transparent;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        // z-index: 2;
                    }
                }
            }
        }
    }

    .actions {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        padding: 0 $blockPadding;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // transition:
        //     padding 0.6s ease-out,
        //     max-height 0.6s ease-out,
        //     opacity 0.6s ease-out;

        transition: all 0.3s ease-out;

        .left-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            column-gap: 8px;

            button {
                // &.resolved {
                //     background-color: #2cad39 !important;
                // }
                // &.unresolved {
                //     background-color: #e38e3f !important;
                // }
            }
        }

        &.show {
            max-height: 100px; // Adjust to the desired maximum height
            opacity: 1;
            padding: $blockPadding;
        }
    }

    > hr {
        border: none;
        border-bottom: 1px solid var(--divider-border-color);
        margin: 0 auto;
        width: calc(100% - 16px);
    }

    > footer {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: space-between;
        @include flex-row(space-between);
        padding: $blockPadding;
        align-items: flex-start;
        .label-container {
            @include flex-column(flex-start);

            label {
                color: var(--alertbox-footer-label-color);
                font-size: $font-size-xsmall;
                margin: 0;
                font-weight: 400;
                line-height: 21px;
                display: inline-flex;
            }
        }

        time {
            color: var(--alertbox-footer-time-color);
            font-size: $font-size-xsmall;
            font-weight: 400;
            line-height: 21px;
        }

        span {
            color: var(--alertbox-footer-span-color);
            font-size: $font-size-xsmall;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &.popup-design {
        .alert-box__header {
            .main-and-subheader {
                .main-heading {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
            }
        }

        > footer {
            // border: 1px solid darkblue;
            .label-container {
                label {
                    color: var(--alertbox-footer-span-color);
                }
            }
        }
    }
}
