@import "../../styles/variables";

.ui-elements-page {
    > header {
        // text-align: center;
        padding: 24px 24px 0 24px;
        font-size: 16px;
        font-weight: 700;
        text-decoration: underline;
    }

    .content {
        padding: 24px;
    }
}
