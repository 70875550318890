.analytics-page {
    width: 100%;
    height: calc(100vh - 100px);
    padding: 24px;
    .char-container {
        width: 100%;
        height: 100%;
        background: #ffffff;
        padding: 24px;
        border-radius: 8px;
        max-width: 1340px;
        max-height: 780px;
        margin: auto;
    }
}
