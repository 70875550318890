@import "../../styles/variables";

$dialogbox-bg-color: var(--dialogbox-bg-color);

.dialogPaper {
    border-radius: 8px !important;
    background-color: var(--dialogbox-bg-color) !important; /* Use variable for dialog background */

    @media (max-width: 600px) {
        max-width: 90%;
        margin: auto;
    }
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--dialogbox-txt-color); /* Use variable for text color */
    padding: 8px 24px !important;
    font-size: $font-size-large !important;
    font-weight: 500;
    background-color: var(--dialogbox-header-bg-color);

    button {
        background: transparent !important;
        color: var(--primary-color) !important; /* Use variable for button color */
        box-shadow: none !important;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;

        .closeButton {
            align-self: flex-end;
        }
    }
}

.dialogContent {
    padding: 0 !important;
    height: 100%;
}

.dialogActions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px !important; /* Adjust padding as needed */

    button {
        font-size: $font-size-base;
        font-weight: 400;
        &.CustomButton_outlined {
            &:hover {
                background-color: var(--dialogbox-outline-btn-hover-bg) !important;
            }
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: stretch;

        button {
            margin-bottom: 8px;
        }
    }
}

.dialogOutlinedButton {
    border: 1px solid #878787 !important;
    color: #878787 !important;
    &:hover {
        background-color: var(--dialogbox-outline-btn-hover-bg) !important; // Override hover state
        border: 1px solid #b4b4b4;
        color: #b4b4b4;
    }
}

.divider {
    border-color: var(--dialogbox-divider-color) !important;
}

.outlinedButton {
    border: 1px solid var(--primary-color); /* Use variable for border color */
    background-color: transparent;
    color: var(--dialogbox-txt-color); /* Use variable for text color */
}

.containedButton {
    background-color: var(--primary-color); /* Use variable for button background */
    color: var(--dialogbox-txt-color); /* Use variable for text color */
}
