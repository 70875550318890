.three-line-loader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 24px;
    height: 32px;

    .line {
        width: 5px;
        height: 100%;
        background-color: #3498db;
        animation: bounce 1.2s infinite ease-in-out;
    }

    .line:nth-child(1) {
        animation-delay: -0.24s;
    }

    .line:nth-child(2) {
        animation-delay: -0.12s;
    }

    .line:nth-child(3) {
        animation-delay: 0;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: scaleY(0.4);
        opacity: 0.2; /* Add fade effect */
    }
    
    50% {
        transform: scaleY(1);
        opacity: 1; /* Full opacity at peak */
    }
}
