// src/styles/themes/light-theme.scss

:root {
    --primary-color: #3253dc;
    --primary-color-dark: #203ebb;
    --primary-color-light: #e0e5fa;
    --secondary-color: #dc004e;
    --background-color: #f5f5f5;
    --text-color: #323232;
    --text-color-light-3x: #424141;
    --text-color-light-5x: #828282;
    --text-color-light-6x: #8693a8;

    --resolved-bg: #2cad39;
    --unresolved-bg: #e38e3f;

    --divider-border-color: rgba(0, 0, 0, 0.12);

    // Header Colors
    --header-bg-color: #0d1826;
    --header-txt-color: #9fb9db;
    --header-selected-nav-link-bg-color: #204b81;
    --header-selected-nav-link-txt-color: #ffffff;

    // Footer Colors
    --footer-background-color: #eeeeee;
    --footer-text-color: #111111;

    // Additional Colors
    --warning-color: #e38e3f;
    --warning-dark-color: #d1741e;
    --success-color: #3253dc;
    --success-dark-color: #203ebb;
    --error-color: #ff4a4a;
    --error-dark-color: #ff1717;

    --gray-color: #6c757d;
    --white-color: #ffffff;
    --black-color: #000000;

    --dialogbox-bg-color: #0d0d0d; // var(--white-color)
    --dialogbox-header-bg-color: rgba(15, 15, 15, 0.8);
    --dialogbox-divider-color: #141414;
    --dialogbox-outline-btn-hover-bg: #201f1f; // #e0e5fa
    --dialogbox-txt-color: var(--white-color); // var(--text-color);
    --dialogbox-content-bg-color: #222222; // var(--text-color);

    // dark
    // --alertbox-bg-color: rgba(0, 0, 0, 0.8);
    // --alertbox-border-color: rgba(0, 0, 0, 0.8);
    // --alertbox-header-color: var(--white-color);
    // --alertbox-subheader-color: var(--text-color-light-6x);
    // --alertbox-subheader-icon-color: var(--primary-color);
    // --alertbox-footer-label-color: var(--white-color);
    // --alertbox-footer-time-color: var(--text-color-light-4x);

    // light
    --alertbox-bg-color: rgba(255, 255, 255);
    --alertbox-border-color: var(--divider-border-color);
    --alertbox-header-color: var(--text-color);
    --alertbox-subheader-color: var(--text-color-light-5x);
    --alertbox-subheader-icon-color: var(--primary-color);
    --alertbox-footer-label-color: var(--text-color);
    --alertbox-footer-span-color: var(--white-color);
    --alertbox-footer-time-color: var(--text-color-light-3x);

    --eventcountbar-bg-color: #eaf3ff;
    --eventcountbar-border-color: #eaf3ff;
    --eventcountbar-header-text-color: #30606b;
    --eventcountbar-subheader-text-color: #1c3b62;

    --footer-bg-color: #e9ecee;
    --footer-txt-color: #3253dc;

    --config-cam-list-bg-color: #f2f5f9;
    --config-cam-list-item-bg-color: var(--white-color);
    --config-cam-list-item-txt-color: var(--text-color);
    --config-cam-list-item-icon-color: #a6a8a8;
    --config-cam-list-hover-item-bg-color: var(--primary-color-light);
    --config-cam-list-hover-item-txt-color: var(--text-color);
    --config-cam-list-hover-item-icon-color: #a6a8a8;
    --config-cam-list-selected-item-bg-color: #97aaed;
    --config-cam-list-selected-item-txt-color: var(--white-color);
    --config-cam-list-selected-item-icon-color: var(--white-color);

    --scrollbar-thumb-color: #68b5c7;
    --scrollbar-thumb-hover-color: #4aa1b4;
    --scrollbar-track-color: #f2f5f9;
}
