@import "../../styles/variables";
@import "../../styles/mixins";

$inputBoxContainerHeight: 72px;

.add-camera-details {
    padding: $padding;
    @include flex-row(space-between);
    align-items: flex-start;
    color: var(--dialogbox-txt-color);
    column-gap: 16px;
    height: 100%;
    height: calc(100vh - 180px);
    max-height: 766px;
    overflow: hidden;

    .left-content,
    .main-content {
        background-color: var(--dialogbox-content-bg-color);
        border-radius: 4px;
    }

    .left-content {
        width: 380px;
        height: 100%;

        > header {
            padding: 16px;
            font-size: $font-size-base;
            // font-weight: 500;
        }

        .form-block {
            height: calc(100% - 74px);
            padding: 0px 16px 16px 16px;
            overflow: hidden;
            overflow-y: auto;

            @include thin-scrollbar(var(--primary-color), var(--dialogbox-content-bg-color), var(--primary-color-dark));

            .form-content {
                @include flex-column(flex-start);
                row-gap: 32px;
                p {
                    color: #68b5c7;
                }
                .row {
                    @include flex-row();
                    column-gap: 16px;
                    width: 100%;
                    .column {
                        flex: 1;
                    }
                }
                .input-block {
                    // @include flex-column(flex-start);
                    // row-gap: 8px;
                    width: 100%;
                    height: $inputBoxContainerHeight;
                    position: relative;

                    .input-fields {
                        width: 100%;
                        height: 100%;
                        > label {
                            font-size: $font-size-small;
                            font-weight: 300;
                            color: #b9b9ba;

                            mark {
                                background-color: transparent;
                                color: #ff0000;
                            }
                        }
                        > input {
                            width: 100%;
                            display: block;
                            height: 46px;
                            border-radius: 6px;
                            border: 1px solid #b9b9ba;
                            background: transparent;
                            padding: 16px;
                            color: #b9b9ba;

                            &:disabled {
                                background: rgba(255, 255, 255, 0.16);
                                // opacity: 0.6;
                            }
                        }
                    }

                    .error-message {
                        position: absolute;
                        left: 0;
                        top: calc($inputBoxContainerHeight + 2px);
                        font-size: $font-size-xsmall;
                        color: #cf6868;
                    }
                }
            }
        }
    }
    .main-content {
        flex: 1 1;
        padding: 16px;
        height: 100%;
        align-content: center;

        > figure {
            width: 100%;
            height: 100%;
            @include flex-row();

            img {
                width: 100%;
                display: block;
            }
        }
    }
}
