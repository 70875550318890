.quadrilateral-overlay-container {
    position: relative;
    width: 100%;
    height: auto; /* Allows height to be calculated based on aspect ratio */
    max-width: 100%; /* Or specific width */
    aspect-ratio: 16 / 9; /* Maintains the 16:9 aspect ratio */
    // width: 710px;

    .background-media {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    .quadrilateral-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 2;
    }

    .point {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: red;
        border: 2px solid darkred;
        transform: translate(-50%, -50%);
        z-index: 3;
        cursor: grab; /* Default cursor */

        &.dragging {
            background-color: darkred;
            border-color: red;
            cursor: grabbing; /* Cursor when dragging */
        }
    }
}
