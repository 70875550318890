// _mixins.scss
@import "./variables";

@mixin confirmationDialogContent() {
    padding: $padding;

    header,
    p {
        font-size: $font-size-base;
        color: var(--dialogbox-txt-color);
    }

    >header {
        font-weight: 500;
        margin-bottom: 16px;
    }

    >p {
        font-weight: 100;
    }
}

// Mixin for ripple effect
@mixin ripple($color) {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        background: $color;
        width: 100px;
        height: 100px;
        transform: scale(0);
        opacity: 0.5;
        pointer-events: none;
        transition:
            transform 0.4s,
            opacity 0.4s;
    }

    &:active:after {
        transform: scale(3);
        opacity: 0;
        transition: 0s;
    }
}

// Mixin for button sizing
@mixin button-size($min-height) {
    min-height: $min-height;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 1.5;
    border: none;
    cursor: pointer;
    outline: none;
    transition:
        background-color 0.3s,
        color 0.3s,
        border-color 0.3s;
}

@mixin flex-row($justifyContent: center) {
    display: flex;
    flex-direction: row;
    justify-content: $justifyContent;
    align-items: center;
}

@mixin flex-column($alignItems: center) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: $alignItems;
}

@mixin custom-scrollbar($width: 2px, $thumb-color: #999, $track-color: #f1f1f1) {
    // scrollbar-width: thin; /* For Firefox */
    // scrollbar-color: $thumb-color $track-color; /* For Firefox */

    /* For Webkit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: $width;
        height: $width;
        /* For horizontal scrollbar */
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-color;
        border-radius: 10px;
        /* Makes the thumb round */
    }

    &::-webkit-scrollbar-track {
        background-color: $track-color;
    }
}

@mixin thin-scrollbar($thumb-color, $track-color, $thumb-hover-color: $thumb-color) {
    // WebKit-based browsers (Chrome, Safari, Edge)
    $size: 6px;
    $radius: 0px;

    // padding-right: $size + 4px;
    &::-webkit-scrollbar {
        width: $size;
        height: $size; // Handle horizontal scrollbar if necessary
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
        border-radius: $radius; // Rounded corners for the track
        margin-left: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-color;
        border-radius: $radius; // Rounded corners for the thumb
        // width: $thumb-size;
        // min-width: $thumb-size;
        // height: $thumb-size;
        // min-height: $thumb-size;
        width: $size;
        height: $size;
        border-left: 2px solid $track-color;
        margin: auto; // Center the thumb within the track
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $thumb-hover-color;
    }

    // Firefox
    // scrollbar-width: thin;
    // scrollbar-color: $thumb-color $track-color;

    // Microsoft Edge (non-WebKit)
    &::-ms-scrollbar {
        width: $size;
        height: $size;
    }

    &::-ms-scrollbar-track {
        background: $track-color;
        border-radius: $radius; // Rounded corners for the track
    }

    &::-ms-scrollbar-thumb {
        background-color: $thumb-color;
        border-radius: $radius; // Rounded corners for the thumb
        // width: $thumb-size;
        // height: $thumb-size;
        width: $size;
        height: $size;
        border: 2px solid $track-color;
    }

    &::-ms-scrollbar-thumb:hover {
        background-color: $thumb-hover-color;
    }

    // // General scrollbar for Edge
    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: $thumb-color $track-color;
    // }
}

@mixin list-header($headerHeight, $headerLabelBg, $headerLabelColor) {
    height: $headerHeight;
    width: 100%;
    @include flex-row(space-between);
    border-bottom: 1px solid rgba(134, 147, 168, 1);
    margin: 16px 0;

    >div {
        background-color: $headerLabelBg;
        height: 46px;
        width: 121px;
        text-align: center;
        @include flex-column();
        height: 100%;
        line-height: 21px;

        h4 {
            font-size: $font-size-base;
            font-weight: 500;
            color: $headerLabelColor;
        }
    }

    .actions {
        @include flex-row();
        column-gap: 8px;

        button {
            // width: 32px;
            // height: 32px;
            background-color: #68b5c7;
        }
    }
}

@mixin alertBoxTheme($theme) {
    @if $theme ==light {
        --primary-color: #3253dc;
        --primary-color-dark: #203ebb;
        --primary-color-light: #e0e5fa;
        --secondary-color: #dc004e;
        --background-color: #f5f5f5;
        --text-color: #323232;
        --text-color-light-3x: #424141;
        --text-color-light-5x: #828282;
        --text-color-light-6x: #8693a8;
        --alertbox-bg-color: rgba(255, 255, 255, 1);
        --alertbox-border-color: var(--divider-border-color);
        --alertbox-header-color: var(--text-color);
        --alertbox-subheader-color: var(--text-color-light-5x);
        --alertbox-subheader-icon-color: var(--primary-color);
        --alertbox-footer-label-color: var(--text-color);
        --alertbox-footer-span-color: var(--white-color);
        --alertbox-footer-time-color: var(--text-color-light-3x);
    }

    @else if $theme ==dark {
        --primary-color: #3253dc;
        --primary-color-dark: #203ebb;
        --primary-color-light: #e0e5fa;
        --secondary-color: #dc004e;
        --background-color: #1e1e1e; // Dark background
        --text-color: #ffffff;
        --text-color-light-3x: #d9d9d9;
        --text-color-light-5x: #828282;
        --text-color-light-6x: #8693a8;
        --alertbox-bg-color: #222222;
        --alertbox-border-color: rgba(50, 83, 220, 1);
        --alertbox-header-color: #fbfbfb;
        --alertbox-subheader-color: #8693a8;
        --alertbox-subheader-icon-color: #8da3ff;
        --alertbox-footer-label-color: var(--white-color);
        --alertbox-footer-span-color: var(--white-color);
        --alertbox-footer-time-color: #8693a8;
    }
}