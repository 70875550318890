.surveillance-camera {
    // border: 1px solid black;
    position: relative;

    .coverage {
        // border: 1px solid black;
        border-radius: 50%;
        background: rgba(255, 104, 104, 0.28);
    }

    .camera {
        // border: 1px solid black;
        position: absolute;
        // transform: translate(75%, -150%);
        top: 0;
        bottom: 16px;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
}
