@import "../../styles/mixins";

$headerHeight: 60px;

.expandable-alert-list {
    // border: 1px solid black;
    background: #f2f5f9;
    height: 100%;
    padding: 8px 16px 0px 16px;

    .list-header {
        // padding: 8px;
        // height: $headerHeight;
        @include list-header($headerHeight, transparent, var(--text-color));
        > div {
            align-items: flex-start;
        }

        .actions {
            button {
                &.all {
                }
                &.resolved {
                    background-color: #2cad39 !important;
                }
                &.unresolved {
                    background-color: #e38e3f !important;
                }
            }
        }

        &.show-search {
            border-bottom: none;
        }
    }

    .list-content {
        height: calc(100% - ($headerHeight + 32px));
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 16px;
        @include thin-scrollbar(
            var(--scrollbar-thumb-color),
            var(--scrollbar-track-color),
            var(--scrollbar-thumb-hover-color)
        );
        // padding: 8px;
        ul {
            list-style: none;
            margin: 0;
            // padding: 8px;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            // border: 1px solid #c2c2c2;
            li {
                border-radius: 4px;
                // border: 1px solid transparent;
                cursor: pointer;
                box-shadow: none;
                transition: all 0.4s ease-out;

                label {
                    cursor: pointer;
                }

                &.selected {
                    cursor: initial;
                    // border: 1px solid #aaa;
                    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
                    // transition: all 0.4s ease-in-out;
                }
            }
        }

        .no-alert-block {
            @include flex-row();
        }

        &.no-alerts {
            @include flex-row();
        }
    }
}
