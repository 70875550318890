@import "../../styles/mixins";

// Default values for fallback
$default-cam-bg-size: 42px;
$default-cam-coverage-size: $default-cam-bg-size * 3;
$default-cam-icon-size: $default-cam-bg-size / 3;

.camera-view {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    // Define default sizes using CSS custom properties
    --cam-bg-size: #{$default-cam-bg-size};
    --cam-coverage-size: #{$default-cam-coverage-size};
    --cam-icon-size: #{$default-cam-icon-size};

    // z-index: -10;

    .coverage {
        width: var(--cam-coverage-size);
        height: var(--cam-coverage-size);
        border-radius: 50%;
        @include flex-row();
        position: relative;
        transition: transform 0.5s ease-in-out;
        z-index: -1; // Ensure coverage is below the camera and pin

        .camera {
            width: var(--cam-bg-size);
            height: var(--cam-bg-size);
            background-color: #38bcdb;
            border-radius: 50%;
            @include flex-row();
            transform: rotate(var(--camera-angle, 0deg)); // Default to 0deg if variable is not set
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            // z-index: 2; // Ensure camera is above coverage
            cursor: pointer;

            svg {
                width: var(--cam-icon-size);
                // height: ; // Set height if needed
            }
        }

        .coverage-layer {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            clip-path: circle(50%);
            transform: translate(-50%, -50%);
            transform-origin: center;
            transition: transform 0.5s ease-in-out;
            background: conic-gradient(
                from calc((var(--camera-angle, 0deg) - var(--field-of-view-angle, 90deg) / 2) - 90deg),
                rgba(56, 188, 219, 0.5) 0deg,
                rgba(56, 188, 219, 0.5) var(--field-of-view-angle, 90deg),
                transparent var(--field-of-view-angle, 90deg),
                transparent 360deg
            );
            z-index: -10; // Ensure this is below the camera and pin
        }

        // &::after {
        //     content: "";
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     width: 100%;
        //     height: 100%;
        //     clip-path: circle(50%);
        //     transform: translate(-50%, -50%);
        //     transform-origin: center;
        //     transition: transform 0.5s ease-in-out;
        //     background: conic-gradient(
        //         from calc((var(--camera-angle, 0deg) - var(--field-of-view-angle, 90deg) / 2) - 90deg),
        //         rgba(56, 188, 219, 0.5) 0deg,
        //         rgba(56, 188, 219, 0.5) var(--field-of-view-angle, 90deg),
        //         transparent var(--field-of-view-angle, 90deg),
        //         transparent 360deg
        //     );
        //     z-index: 0; // Ensure this is below the camera and pin
        // }
    }

    .pin {
        position: absolute;
        top: 50%; // Center vertically
        left: 50%; // Center horizontally
        transform: translate(-50%, -116%); // Center the pin exactly at the top of the camera
        z-index: 3; // Ensure pin is on top of everything
    }
}
