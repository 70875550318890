@import "../../styles/variables";
@import "../../styles/mixins";

.incident-feed-and-location {
    width: 100%;
    color: white;
    height: 100%;
    @include flex-column();
    row-gap: 16px;
    position: relative;
    transition: all 0.3s ease-in-out;

    .container {
        @include flex-row();
        width: 100%;
        height: 50%;
        height: calc(50% - 8px);
        gap: 16px;
        list-style: none;
        padding: 0;
        margin: 0;
        transition: all 0.3s ease-in-out;

        &.inner-container {
            row-gap: 16px;
        }

        .item {
            @include flex-column();
            color: white;
            width: 100%;
            height: 100%;
            background: #222222;

            .content {
                height: 100%;
                width: 100%;
                position: relative;

                >figure {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }

                    >.error-message {
                        display: none;
                        /* Hidden by default */
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: white;
                        font-size: 16px;
                        text-align: center;
                        background-color: rgba(0, 0, 0, 0.6);
                        padding: 10px;
                        border-radius: 4px;
                    }
                }

                .actions {
                    position: absolute;
                    bottom: 8px;
                    right: 8px;
                    z-index: 11;

                    button {
                        background-color: rgba(0, 0, 0, 0.2);
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.4);
                        }
                    }
                }

                .loader {
                    width: 100%;
                    height: 100%;
                    @include flex-column();
                }
            }

            &.nested {
                background: transparent;

                >.content {
                    position: static;
                    @include flex-column();
                    row-gap: 16px;
                    height: 100%;
                }
            }

            &.cam-zone .content {
                .zone-container {
                    background: #222222;
                    width: 100%;
                    height: 100%;
                    @include flex-column();
                    padding: 12px;

                    .zoomable-image-container {
                        height: 100%;
                        max-width: 100%;
                    }
                }
            }

            &.live-feed, &.recording {
                > .content {
                    height: initial;
                }
            }

            &.live-feed,
            &.recording,
            // &[class^="incident-images"] {
            & {
                .content>figure {
                    width: 100%;
                    height: 100%;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }

                    video,
                    iframe {
                        width: 100%;
                        height: 100%;
                        border: none;
                        object-fit: cover; // Ensures the media fits within the container
                    }
                }
            }

            &.max {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 12;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}