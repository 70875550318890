@import "../../styles/variables";
@import "../../styles/mixins";

.search-box {
    @include flex-row(space-between);
    border-bottom: 1px solid #ddd;
    width: 100%;
    height: 100%;
    // max-height: 42px;
    column-gap: 8px;

    @include flex-row();
    // outline: 1px solid blue;
    input {
        border: none;
        height: 38px;
        padding: 8px 8px 8px 16px;
        // outline: 1px solid red;
        // width: calc(100% - 44px);
        width: 100%;
        font-size: $font-size-base;
        background: transparent;
    }

    &.focused {
        border-bottom: 1px solid red;
    }

    // button {
    //     background-color: white !important;
    //     border-radius: 0 !important;

    //     svg path {
    //         stroke: black;
    //     }
    // }
}
