@import "../../styles/variables";
@import "../../styles/mixins";

$headerColor: #132740;

$actionContainerBorderColor: rgba(154, 178, 249, 1);
$actionContainerPadding: 16px;

$sideContentWidth: 512px;

.custom-alert-config {
    @include flex-column();
    row-gap: 8px;

    .custom-alert-block {
        // border: 1px solid black;
        @include flex-row();
        align-items: flex-start;
        column-gap: 16px;
        border: 1px solid $actionContainerBorderColor;
        padding: $actionContainerPadding;
        border-radius: 8px;
        width: 100%;
        position: relative;

        .cam-view {
            flex: 1;

            // width: calc(100% - $sideContentWidth);
            .alert-action-header {
                @include flex-row(space-between);
                column-gap: 12px;
                // align-items: stretch;
                // border: 1px solid black;
                padding: 0 0px 16px 16px;
                height: 52px;

                >article {
                    @include flex-row();
                    column-gap: 8px;

                    span {
                        font-size: $font-size-large;
                        font-weight: 500;
                        color: $headerColor;
                        @include flex-row(space-between);
                        column-gap: 8px;

                        i {
                            @include flex-row(space-between);
                            cursor: pointer;
                            position: relative;
                            font-style: normal; 

                            pre {
                                background-color: #666666;
                                font-family: inherit;
                                width: 386px;
                                white-space: pre-wrap;
                                word-wrap: break-word;
                                overflow-wrap: break-word;
                                padding: 12px 16px;
                                border-radius: 4px;
                                position: absolute;
                                left: 32px;
                                color: white;
                                z-index: 1;
                                font-size: $font-size-small;
                                display: none;
                                cursor: default;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: -14px; /* Position it to the left of the <pre> box */
                                    transform: translateY(-50%);
                                    border-width: 8px; /* Size of the triangle */
                                    border-style: solid;
                                    border-color: transparent #666666 transparent transparent; /* Left-pointing triangle */
                                }
                            }

                            &:hover {
                                pre {
                                    display: block;
                                }
                            }

                        }
                    }
                }

                button {
                    width: 170px;

                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            // border: 1px solid black;

            >figure {
                // height: 350px;
                width: 100%;
                height: auto;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }

        .alert-details {
            // border: 1px solid black;
            width: $sideContentWidth;
            margin-top: 52px;
            @include flex-column();
            row-gap: 12px;
            align-items: stretch;
            padding: 0px 32px;

            >header {
                font-size: $font-size-large;
                font-weight: 500;
            }

            .input-block {
                @include flex-column(flex-start);

                // row-gap: 12px;
                label {}

                input {
                    height: 38px;
                    border-radius: 4px;
                    width: 200px;
                    border: 1px solid #cccccc;
                    padding: 0px 16px;
                }
            }

        }
        .submit-btn {
            position: absolute;
            bottom: 16px;
            right: 16px;
        }
    }
}

.proximity-area {
    @include flex-column();
    overflow: hidden;
    height: calc(100% - 2px);

    .shapes {
        @include flex-row(flex-start);
        gap: 8px;
        width: 100%;
        padding: 4px 24px;

        label {
            color: var(--white-color);
        }

        ul {
            li {
                width: 36px;
                height: 36px;
                cursor: pointer;
                background: #0f0f0f;
                @include flex-row();

                &:hover {
                    background: lighten(#0f0f0f, 10%);
                }

                &.selected {
                    background: var(--primary-color);
                }
            }
        }
    }

    >.content {
        width: 100%;
        height: 100%;
        padding: 0px 24px;
        position: static;

        .drawing-area {
            // max-width: 991px;
            // max-height: 557px;
            width: auto;
            height: auto;

            // max-width: 100%;
            // max-height: calc(100vh - 300px);
            // max-height: 90%;
            // aspect-ratio: 16 / 9;
            .quadrilateral-overlay-container {

                // height: 100%;
                // width: auto;
                // max-height: 100%;
                // aspect-ratio: 16 / 9;
                video {
                    // width: 552px;
                    // height: 310px;
                }
            }
        }
    }
}

.confirm-dialog-content {
    @include confirmationDialogContent();
}