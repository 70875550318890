@import "../../styles/mixins";

.pin {
    position: relative;
    cursor: pointer !important;

    figcaption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 10px;
        font-size: $font-size-base !important;
        color: #fff;
        // display: flex;
        // flex-direction: row;
        // align-content: center;
        // justify-content: center;
        // align-items: flex-start;
        @include flex-row();
        padding-bottom: 14px;
        user-select: none;
    }
}
