@import "../../styles/variables";

// Button size
$buttonSize: 32px;

@mixin buttonHoverAndDisableStyles($hoverBgColor, $disabledColor) {
    &:hover {
        background-color: $hoverBgColor;
    }
    &:disabled {
        background-color: $disabledColor;
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.button {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: none !important;
    border-radius: 20px !important;
    height: $buttonSize;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    border: none;
    transition:
        background-color 0.3s ease,
        border-color 0.3s ease;
    cursor: pointer;

    &.contained {
        background-color: var(--primary-color);
        color: white;
        @include buttonHoverAndDisableStyles(var(--primary-color-dark), var(--primary-color));
    }

    &.outlined {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        background-color: transparent;
        @include buttonHoverAndDisableStyles(var(--primary-color-light), var(--primary-color));
    }

    &.warning {
        background-color: var(--warning-color);
        color: white;
        @include buttonHoverAndDisableStyles(var(--warning-dark-color), var(--warning-color));
    }

    &.success {
        background-color: var(--success-color);
        color: white;
        @include buttonHoverAndDisableStyles(var(--success-dark-color), var(--success-color));
    }

    &.error {
        background-color: var(--error-color);
        color: white;
        @include buttonHoverAndDisableStyles(var(--error-dark-color), var(--error-color));
    }
}

.iconOnly {
    border-radius: 50%; // Fully rounded for icon-only buttons
    width: $buttonSize;
    height: $buttonSize;
    padding: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Shadow for icon-only buttons
    background-color: transparent; // No background
    border: none; // No border
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}
