@import "../../styles/variables";
@import "../../styles/mixins";

$headerColor: #132740;
$headerBorderColor: rgba(0, 0, 0, 0.05);

$actionContainerBorderColor: rgba(154, 178, 249, 1);
$actionContainerPadding: 16px;

$listBorderColor: rgba(50, 83, 220, 1);

$sideContentWidth: 512px;

.config-action {
    // border: 1px solid black;
    // @include flex-column(stretch);
    // row-gap: 16px;
    // margin-top: 8px;
    padding: 24px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    @include thin-scrollbar(
        var(--scrollbar-thumb-color),
        var(--scrollbar-track-color),
        var(--scrollbar-thumb-hover-color)
    );

    .action-list-and-zone,
    .custom-alert-block {
        margin-top: 24px;
    }

    .config-action-header {
        // border-bottom: 1px solid $headerBorderColor;
        border-bottom: 1px solid rgb(134, 147, 168);
        height: 48px;
        padding: 8px 0;
        label {
            // border: 1px solid black;
            font-size: $font-size-large;
            font-weight: 500;
            color: $headerColor;
        }
    }

    .action-list-and-zone {
        // border: 1px solid black;
        @include flex-row();
        align-items: flex-start;
        column-gap: 16px;

        .selection-list,
        .camera-location {
            border: 1px solid $actionContainerBorderColor;
            padding: $actionContainerPadding;
            border-radius: 8px;
            height: 362px;
            > figure {
                @include flex-row();
                .zoomable-image-container {
                    height: 100%;
                    max-width: 100%;
                }
            }

        }

        .selection-list {
            flex: 1;
            width: 100%;

            margin: 0;
            list-style: none;
            @include flex-column(stretch);
            row-gap: 12px;
            // align-items: stretch;
            justify-content: flex-start;
            overflow-y: auto;

            @include thin-scrollbar(
                var(--scrollbar-thumb-color),
                var(--scrollbar-track-color),
                var(--scrollbar-thumb-hover-color)
            );

            li {
                // border: 1px solid black;
                // height: 68px;
                border: 1px solid $listBorderColor;
                border-radius: 8px;
                @include flex-row(space-between);
                padding: 8px 12px;

                > article {
                    @include flex-row();
                    column-gap: 8px;

                    span {
                        font-size: $font-size-small;
                        font-weight: bold;
                    }
                }
            }
        }

        .camera-location {
            // flex: 1 1;
            width: $sideContentWidth;
            // height: 362px;
            // height: 100%;
            height: 362px;

            > header {
            }

            > figure {
                width: 100%;
                height: calc(100% - 26px);
            }
        }
    }

    .custom-alert {
        @include flex-column();
        row-gap: 8px;

        .custom-alert-block {
            // border: 1px solid black;
            @include flex-row();
            align-items: flex-start;
            column-gap: 16px;
            border: 1px solid $actionContainerBorderColor;
            padding: $actionContainerPadding;
            border-radius: 8px;

            .cam-view {
                flex: 1;
                // width: calc(100% - $sideContentWidth);
                .alert-action-header {
                    @include flex-row(space-between);
                    column-gap: 12px;
                    // align-items: stretch;
                    // border: 1px solid black;
                    padding: 0 0px 16px 16px;
                    height: 52px;

                    > article {
                        @include flex-row();
                        column-gap: 8px;

                        span {
                            font-size: $font-size-large;
                            font-weight: 500;
                            color: $headerColor;
                        }
                    }
                }
                // border: 1px solid black;

                > figure {
                    // height: 350px;
                    width: 100%;
                    height: auto;
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }

            .alert-details {
                // border: 1px solid black;
                width: $sideContentWidth;
                margin-top: 52px;
                @include flex-column();
                row-gap: 12px;
                align-items: stretch;
                padding: 0px 32px;

                > header {
                    font-size: $font-size-large;
                    font-weight: 500;
                }
                .input-block {
                    @include flex-column(flex-start);
                    // row-gap: 12px;
                    label {
                    }

                    input {
                        height: 38px;
                        border-radius: 4px;
                        width: 200px;
                        border: 1px solid #cccccc;
                        padding: 0px 16px;
                    }
                }
            }
        }

        .custom-alert-action {
            @include flex-row();
            width: 100%;
            justify-content: flex-end;
            width: 100%;
        }
    }

    .no-camera-selected {
        @include flex-column();
        row-gap: 8px;
        height: 100%;
    }
}
