@import "../../styles/mixins";

.zoomable-image-container {
    position: relative;
    display: inline-block;
    user-select: none; // Prevents selection
    // border: 1px solid black;
}

.zoomable-figure {
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

.zoomable-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    user-select: none; // Prevents selection
    cursor: grab;
    position: relative;
    z-index: 1; // Ensure the image is below the overlay

    &:active {
        cursor: grabbing;
    }
}

.overlay-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; // Allows interactions to pass through to the image underneath
    z-index: 10; // Ensure the overlay is on top of the image
}

.overlay-label {
    background-color: rgba(255, 255, 255, 0.7); // Example background color for the label
    border: 1px solid #000; // Example border for visibility
    border-radius: 3px;
    text-align: center;
    white-space: nowrap;
    transform: translate(-50%, -50%); // Center the label
    box-sizing: border-box; // Ensure padding and border are included in the element's total width and height
}

.controls {
    margin-top: 10px;
    @include flex-row(flex-end);

    button {
        margin-right: 5px;
        padding: 5px 10px;
    }
}
