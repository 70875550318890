// Example spacing variables
$spacing-unit: 8px;
$spacing-small: $spacing-unit * 2;
$spacing-medium: $spacing-unit * 3;
$spacing-large: $spacing-unit * 4;

// Example font variables
$font-family-base: "Roboto", sans-serif;
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
//     "Droid Sans", "Helvetica Neue", sans-serif;

$font-size-xxsmall: 1rem;
$font-size-xsmall: 1.2rem;
$font-size-small: 1.4rem;
$font-size-base: 1.6rem;
$font-size-large: 1.8rem;
$font-size-xlarge: 2rem;
$font-size-xxlarge: 2.2rem;
$font-size-xxxlarge: 2.4rem;

// Button variables
$button-padding: 12px 24px;
$button-height: 40px;
$button-font-size: 1rem; // 10px base for rem
$button-ripple-duration: 0.6s;

$eventcountbar-index-1-icon-bg-color: #97aaed;
$eventcountbar-index-2-icon-bg-color: #47b3cc;
$eventcountbar-index-3-icon-bg-color: #62acff;
// $eventcountbar-index-4-icon-bg-color: #f1933b;
$eventcountbar-index-4-icon-bg-color: #5bc7b3;
// $eventcountbar-index-5-icon-bg-color: #1cab91;
$eventcountbar-index-5-icon-bg-color: #4774e8;
$eventcountbar-index-6-icon-bg-color: #556587;

$padding: 24px;
$padding-horizontal: 0 $padding;
$padding-vertical: $padding 0;

$headerHeight: 64px;
$footerHeight: 24px;
