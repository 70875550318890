/** 
    IMP: Never call this file in multiple files, otherwise this slow the CSS by recalling several times. 
    We can inspect that behaviour in developer tool.
    Use only in App/Index.tsx
*/
@import "variables";
@import "mixins";
@import "color";

/* ------------------------ */
/* 1. CSS Reset / Normalize */
/* ------------------------ */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

html {
    font-size: 62.5%; /* 1rem = 10px */
}
body {
    height: 100%;
    line-height: 1.6 !important;
    font-family: $font-family-base !important;
    font-size: $font-size-base !important;
    font-weight: 400 !important;

    background-color: var(--background-color, #f8f9fa);
    color: var(--text-color, #333);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// figure {
//     padding: 0;
//     margin: 0;
// }

// code {
//     font-family: "Roboto", sans-serif;
// }

// /* ------------- */
// /* 2. Typography */
// /* ------------- */
// // h1,
// // h2,
// // h3,
// // h4,
// // h5,
// // h6 {
// //     margin-bottom: 1.6rem;
// //     font-weight: 700;
// // }

// // p {
// //     margin-bottom: 1.6rem;
// //     font-size: 1.6rem; /* 16px */
// // }

// // a {
// //     color: var(--primary-color, #007bff);
// //     text-decoration: none;

// //     &:hover {
// //         text-decoration: underline;
// //     }
// // }

// /* ------------------------- */
// /* 3. Generic Layout Helpers */
// /* ------------------------- */
// .container {
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 0 1.6rem;
// }

// .row {
//     display: flex;
//     flex-wrap: wrap;
//     margin-right: -1.6rem;
//     margin-left: -1.6rem;
// }

// .col {
//     flex: 1;
//     padding-right: 1.6rem;
//     padding-left: 1.6rem;
// }

// /* ------------------ */
// /* 4. Utility Classes */
// /* ------------------ */
// .mt-1 {
//     margin-top: 0.8rem;
// }
// .mt-2 {
//     margin-top: 1.6rem;
// }
// .mt-3 {
//     margin-top: 2.4rem;
// }
// .mb-1 {
//     margin-bottom: 0.8rem;
// }
// .mb-2 {
//     margin-bottom: 1.6rem;
// }
// .mb-3 {
//     margin-bottom: 2.4rem;
// }

// .text-center {
//     text-align: center;
// }
// .text-left {
//     text-align: left;
// }
// .text-right {
//     text-align: right;
// }

// /* ---------------- */
// /* 5. Button Styles */
// /* ---------------- */
// // .button {
// //     display: inline-block;
// //     padding: 0.8rem 1.6rem;
// //     font-size: 1.6rem;
// //     font-weight: 500;
// //     text-align: center;
// //     text-decoration: none;
// //     border-radius: 0.4rem;
// //     color: #fff;
// //     background-color: var(--primary-color, #007bff);
// //     border: none;
// //     cursor: pointer;
// //     transition: background-color 0.3s ease;

// //     &:hover {
// //         // background-color: darken(var(--primary-color, #007bff), 10%);
// //         background-color: var(--primary-color, #007bff);
// //     }
// // }

// /* ---------------- */
// /* 6. Media Queries */
// /* ---------------- */
// @media (max-width: 768px) {
//     .container {
//         padding: 0 1rem;
//     }

//     .row {
//         flex-direction: column;
//     }

//     .col {
//         flex: none;
//         width: 100%;
//         padding: 1rem 0;
//     }
// }
